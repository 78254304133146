

/* .imgHome {
	box-shadow: 2px 2px 143px 46px rgba(232,214,214,1);
-webkit-box-shadow: 2px 2px 143px 46px rgba(232,214,214,1);
-moz-box-shadow: 2px 2px 143px 46px rgba(232,214,214,1);
} */


.card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    /* width: 360px; */
    /* height: 400px; */
    background: #000;
}

.card .image {
    width: 100%;
    height: 100%;
    overflow: hidden;
		box-shadow: 2px 2px 143px 46px rgba(232,214,214,1);
-webkit-box-shadow: 2px 2px 143px 46px rgba(232,214,214,1);
-moz-box-shadow: 2px 2px 143px 46px rgba(232,214,214,1);
}
.card .image img {
    width: 100%;
    transition: .5s;
}
.card:hover .image img {
    opacity: .5;
    transform: translateX(30%);/*100%*/
}
.card .details {
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;/*100%*/
    height: 100%;
    background: #ffc107;
    transition: .5s;
    transform-origin: left;
    transform: perspective(2000px) rotateY(-90deg);
}
.card:hover .details {
    transform: perspective(2000px) rotateY(0deg);
}
.card .details .center {
    padding: 20px;
    text-align: center;
    background: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.card .details .center h1 {
    margin: 0;
    padding: 0;
    color: #ff3636;
    line-height: 20px;
    font-size: 20px;
    text-transform: uppercase;
}
.card .details .center h1 span {
    font-size: 14px;
    color: #262626;
}
.card .details .center p {
    margin: 10px 0;
    padding: 0;
    color: #262626;
}
.card .details .center ul {
    margin: 10px auto 0;
    padding: 0;
    display: table;
}
.card .details .center ul li {
    list-style: none;
    margin: 0 5px;
    float: left;
}
.card .details .center ul li a {
    display: block;
    background: #262626;
    color: #fff;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    transform: .5s;
}
