.weather-sun,.weather-cloud,.weather-snow,.weather-cloudAndSun,.weather-rain{
				/* width: 20px; */
				/* height: 20px; */
				transform: scale(0.3);
				display: inline-block;
				position: relative;
			}
			
			.weather-cloudAndSun,.weather-rain{
				margin-left: 60px;
			}
			
			.sun{
				width: 100px;
				height: 100px;
				background: linear-gradient(45deg,#F3A966,yellow);
				border-radius: 100%;
				transform: rotate(0deg);
				animation: xuanzhuang 12s infinite linear;
				position: absolute;
				left: 50%;
				top: 50%;
			}
			
			.rays{
				width: 8px;
				height: 15px;
				background: yellow;
				position: absolute;
				transform: translateX(-50%);
				left: 50%;
				top: -25px;
				box-shadow: 0 135px yellow;
				border-radius: 4px;
				transform-origin: 0px 75px;
			}
			
			.rays:nth-child(2){
				transform: rotate(45deg);
			}
			
			.rays:nth-child(3){
				transform: rotate(90deg);
			}
			
			.rays:nth-child(4){
				transform: rotate(135deg);
			}
			
			@keyframes xuanzhuang {
				100% { transform: rotate(360deg); }
			}
			
			
			.cloud{
				border-radius: 25px;
				position: absolute;
				top: 70%;
				left: 50%;
				width: 150px;
				height: 50px;
				background: #cdedf8;
			}
			
			.cloud:nth-child(2){
				left: 200px;
				width: 100px;
				height: 30px;
				background: #cdedf8;
				opacity: 1;
				animation: cloud 8s linear infinite;
			}
			
			.cloud::after,.cloud::before{
				content: '';
				display: block;
				background: #cdedf8;
				border-radius: 50%;
				position: absolute;
				
			}
			
			.cloud:nth-child(2)::after,.cloud:nth-child(2)::before{
				content: '';
				display: block;
				background: #cdedf8;
				border-radius: 50%;
				position: absolute;
				
			}
			
			.cloud::after{
				width: 80px;
				height: 80px;
				left: 45px;
				top: -45px;
			}
			
			.cloud::before{
				width: 60px;
				height: 60px;
				top: -10px;
				left: 0;
			}
			
			.cloud:nth-child(2)::after{
				width: 50px;
				height: 50px;
				left: 35px;
				top: -30px;
			}
			
			.cloud:nth-child(2)::before{
				width: 40px;
				height: 40px;
				top: -10px;
				left: 0px;
			}
			
			@keyframes cloud {
				0% { opacity: 0; }
				50% { opacity: 1; }
				100% {
					opacity: 0;
					transform: scale(0.8) translateX(-200%);
				}
			}
			
			.snow{
				width: 150px;
				height: 10px;
				border-radius: 5px;
				background: #CDEDF8;
				transform: rotate(0deg);
				position: absolute;
				top: 70%;
				left: 60%;
				animation: Rotate 12s infinite linear;
				position: relative;
			}
			
			.snow::after,.snow::before{
				content: '';
				width: 150px;
				height: 10px;
				border-radius: 5px;
				background: #CDEDF8;
				position: absolute;
			}
			
			.snow::after{
				transform: rotate(60deg);
			}
			
			.snow::before{
				transform: rotate(120deg);
			}
			
			.f{
				width: 28px;
				height: 10px;
				border-radius: 5px;
				position: absolute;
				background: #CDEDF8;
				top: -45px;
				left: 25px;
				box-shadow: 0px 90px #CDEDF8,72px 90px #CDEDF8,72px 0px #CDEDF8;
			}
			
			.f::after,.f::before{
				content: '';
				width: 28px;
				height: 10px;
				transform: rotate(60deg);
				border-radius: 5px;
				position: absolute;
				background: #CDEDF8;
				top: -10px;
				left: 55px;
				box-shadow: 0px 90px #CDEDF8,72px 90px #CDEDF8,72px 0px #CDEDF8;
			}
			
			.f::before{
				transform: rotate(120deg);
				left: 15px;
				box-shadow: 72px 0px #CDEDF8,72px -90px #CDEDF8,0px -90px #CDEDF8;
			}
			
			@keyframes Rotate {
				100% { transform: rotate(360deg); }
			}
			
			.weather-cloudAndSun .cloud{
				z-index: 1;
				opacity: 0.95;
			}
			
			.weather-cloudAndSun .sun{
				position: absolute;
				left: 180px;
				top: 60px;
				z-index: 0;
			}
			
			.rain{
				width: 4px;
				height: 40px;
				background:linear-gradient(transparent,lightblue);
				position: absolute;
				left: 210px;
				top: 170px;
				border-radius: 2px;
				animation: rainning 2s infinite;
			}
			
			.rain:nth-child(2){
				height: 20px;
				left: 190px;
				top: 190px;
			}
			
			.rain:nth-child(3){
				height: 50px;
				left: 170px;
				top: 180px;
			}
			
			.rain:nth-child(4){
				height: 25px;
				left: 150px;
				top: 185px;
			}
			
			@keyframes rainning{
				100%{
					top: 200px;
				}
			}