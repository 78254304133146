/* default */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
html, body {
  height: 100%;
}
/* body */
body {
    min-height: 100%;
  font-family: 'Rozha One', serif;
  background: hsl(255deg 6% 5% / 80%) !important;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
.ant-carousel .slick-dots  {
	 
		margin-bottom: 50px;
		width: auto !important;
		display: none;
	
}